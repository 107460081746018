import { deleteSondaSuccess } from "../store/sonda/sondaSlice";

const en = {
  translation: {
    common: {
      sonda: "Sonda",
      moisture: "Moisture",
      temperature: "Temperature",
      nitrate: "Nitrate",
      phosphate: "Phosphate",
      ph: "pH",
      ec: "EC",
      errorMessage: "Something went wrong",
      footer: "We're here to help. Contact us info@nerite.tech",
    },
    navBar: {
      myFields: "My Fields",
      myAccount: "My Account",
      about: "About",
      termsOfService: "Terms of service",
      logOut: "Log out",
      openMainMenu: "Open main menu",
    },
    logInPage: {
      logIn: "Log in",
      email: "Email*",
      password: "Password*",
    },
    startMonitoringPage: {
      startMonitoringTitle: "Start soil monitoring",
      startMonitoringText:
        "Add your first field and connect Sonda to it and start getting real-time measurements of the soil.",
      startMonitoringButton: "Add Field",
    },
    addFieldPage: {
      addFieldTitle: "Add a field",
      fieldName: "Field name*",
      cancelButton: "Cancel",
      saveButton: "Save Field",
    },
    newSondaPage: {
      newSondaTitle: "Enter a Sonda code",
      newSondaSubTitle:
        "Connect Sondas to your fields to start real-time measurements.",
      newSondaText:
        "You must put the Sonda on the field before entering the code. You can find the activation code in the Sonda box and in the confirmation email from us.",
      newSondaLaterButton: "Do It Later",
      newSondaConnectButton: "Connect",
      saveFieldSuccessAlert:
        "Your Field was added. Connect a Sonda to it to start measurements.",
    },
    addSondaPage: {
      addSondaTitle: "Confirm Sonda's location",
      cancelButton: "Cancel",
      assignSondaButton: "Assign Sonda To Field",
      sondaError:
        "We couldn't locate the Sonda. Please try again or contact info@nerite.tech",
    },
    fieldListPage: {
      allFields: "All fields",
      addFieldButton: "Add Field",
      noMeasurements: "No measurements were taken yet.",
      recentMeasurements: "Recent measurements were taken",
      battery: "Battery",
      connection: "Connection Strength",
      sondasOnFieldSectionTitle: "Sondas on this field",
      noSonda: "No Sondas were added yet.",
      addSondaButton: "Add Sonda",
      fieldDetailsButton: "See field details",
      saveSondaSuccessAlert: "New Sonda added to the field",
      deleteSondaSuccessAlert: "The Sonda was deleted.",
      deleteSonda: "Delete Sonda.",
      deleteField: "Delete field.",
      deleteSondaModalContent: "Delete the Sonda?",
      deleteFieldModalContent: "Delete the field?",
      deleteFieldSuccessAlert: "The field was deleted.",
      closeModal: "Close",
    },
    fieldDetailsPage: {
      backLink: "Back to All Fields",
      selectMeasurementType: "Select measurement type",
      optimalRange: "Optimal range",
      saveOptimalRange: "Save",
    },
    imgAlt: {
      close: "Close icon",
      tickBox: "Tick box icon",
    },
    mapDetails: {
      markArea: "Mark the area for satellite data.",
      usePanel: "Use the panel in the top right corner of the map.",
      loading: "Loading...",
      downloadingData: "Downloading the latest data...",
      imageDate: "Image date:",
      zoomInText: "Zoom in",
      zoomOutText: "Zoom out",
    },
    optimalRangeErrors: {
      moistureNotInRange:
        "The optimal range is incorrect. The appropriate range for moisture is from 0 to 100%.",
      temperatureNotInRange:
        "The optimal range is incorrect. The minimum temperature cannot be less than -273.15 °C.",
      nitrateNotInRange:
        "The optimal range is incorrect. The minimum value of nitrate must be greater than or equal to 0.",
      phosphateNotInRange:
        "The optimal range is incorrect. The minimum value of phosphate must be greater than or equal to 0.",
      phNotInRange:
        "The optimal range is incorrect. The appropriate pH range is from 0 to 14.",
      ecNotInRange:
        "The optimal range is incorrect. The minimum value of EC range must be greater than 0.",
      minHigherThanMax:
        "The minimum value cannot be higher than the maximum value.",
    },
    scriptNames: {
      moistureIndex: "Moisture Index",
      ndvi: "NDVI",
      trueColor: "True Color",
    },
    sondaErrors: {
      lowBattery: "Low battery",
      soilSamplingError: "Soil sampling error",
      timeSynchronizationError: "Time synchronization error",
      locationSynchronizationError: "Location synchronization error",
      nutrientSensorError: "Nutrient sensor error",
      pneumaticSystemError: "Pneumatic system error",
      soilTemperatureSensorError: "Soil temperature sensor error",
      solutionTemperatureSensorError: "Solution temperature sensor error",
      caseClimateSensorError: "Case climate sensor error",
      caseTemperatureTooHigh: "Case temperature too high",
      caseTemperatureTooLow: "Case temperature too low",
      softwareError: "Software error",
      soilTooDryForSampling: "Soil too dry for sampling",
      solutionExtractionError: "Solution extraction error",
    },
  },
};

export default en;
