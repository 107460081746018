import { LatLngExpression } from "leaflet";
import moment from "moment";
import React, { FC, useEffect } from "react";
import Popup from "reactjs-popup";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ActionPanel from "../components/ActionPanel";
import Map from "../components/Map";
import { Field, deleteField } from "../store/fields/fieldsSlice";
import { Sonda } from "../store/sonda/sondaSlice";
import Measurement from "./Measurement";
import SondaList from "./SondaList";
import { useAppDispatch } from "../store";
import AlertModalField from "./AlertModalField";
import {
  ArrowRightIcon,
  EllipsisVerticalIcon,
} from "@heroicons/react/24/outline";

interface FieldProps {
  field: Field;
}

const FieldListItem: FC<FieldProps> = ({ field }) => {
  const { t } = useTranslation("translation");

  const dispatch = useAppDispatch();

  const deleteFieldOnClick = (event) => {
    event.preventDefault();
    dispatch(deleteField(fieldId));
  };

  let lastMeasurementText: string;
  const { fieldId, name, lat, lng, sondas } = field;

  const measurementsPresent: boolean = sondas
    .map((sonda) => sonda.measurements?.length !== 0)
    .includes(true);

  const calculateMinMax = (measurements: any[]) => {
    const validMeasurements = measurements.filter((m: number) => !isNaN(m));
    const hasValidData = validMeasurements.length > 0;

    const min = hasValidData ? Math.min(...validMeasurements) : "No Data";
    const max = hasValidData ? Math.max(...validMeasurements) : "No Data";

    return { min, max };
  };

  const extractLastValidMeasurements = (
    sondas: Sonda[],
    measurementType: string
  ) => {
    return sondas
      .map((sonda) => {
        const measurement = sonda.latest_valid?.[measurementType]?.[0];
        return measurement ? parseFloat(measurement) : null;
      })
      .filter((m) => m !== null);
  };

  const extractTimestamps = (sondas: Sonda[], measurementType: string) => {
    return sondas
      .map((sonda) => {
        const timestamp = sonda.latest_valid?.[measurementType]?.[1];
        return timestamp ? timestamp : null;
      })
      .filter((t) => t !== null);
  };

  const getLastValidMeasurementData = () => [
    {
      title: "common.moisture",
      measurements: extractLastValidMeasurements(sondas, "moisture"),
      datetime: extractTimestamps(sondas, "moisture"),
      unit: "%",
    },
    {
      title: "common.temperature",
      measurements: extractLastValidMeasurements(sondas, "temperature"),
      datetime: extractTimestamps(sondas, "temperature"),
      unit: "°C",
    },
    {
      title: "common.nitrate",
      measurements: extractLastValidMeasurements(sondas, "nitrate"),
      datetime: extractTimestamps(sondas, "nitrate"),
      unit: "ppm",
    },

    // temporarily hiding phosphate and ph
    // make sure to change the amount of columns in FieldListItem.tsx (134) columns-x

    // {
    //   title: "common.phosphate",
    //   measurements: extractLastValidMeasurements(sondas, "phosphate"),
    //   datetime: extractTimestamps(sondas, "phosphate"),
    //   unit: "",
    // },
    // {
    //   title: "common.ph",
    //   measurements: extractLastValidMeasurements(sondas, "ph"),
    //  datetime: extractTimestamps(sondas, "ph"),
    //   unit: "",
    // },
    {
      title: "common.ec",
      measurements: extractLastValidMeasurements(sondas, "ec"),
      datetime: extractTimestamps(sondas, "ec"),
      unit: "dS/m",
    },
  ];

  const determineUnit = (min: number | string, defaultUnit: string) =>
    min !== "No Data" ? defaultUnit : "";

  if (measurementsPresent) {
    // @ts-ignore
    const lastMeasurementDate: string = sondas
      .map((sonda) => sonda.measurements?.[0].__dt)
      .sort()
      .reverse()[0];
    const formattedLastMeasurementDate =
      moment(lastMeasurementDate).format("DD/MM/YYYY HH:mm");

    lastMeasurementText = `${t(
      "fieldListPage.recentMeasurements"
    )} ${formattedLastMeasurementDate}.`;
  } else {
    lastMeasurementText = t("fieldListPage.noMeasurements");
  }

  const mapCenter: LatLngExpression = {
    lat: lat,
    lng: lng,
  };

  const renderSondaList = (sondas: Sonda[]) => {
    if (sondas.length === 0) {
      return <p className="text-sm my-4">{t("fieldListPage.noSonda")}</p>;
    } else {
      return <SondaList sondas={sondas} fieldId={fieldId} />;
    }
  };

  return (
    <ActionPanel key={fieldId}>
      <div className="lg:flex">
        <div className="shrink mb-4 sm:mb-0 sm:mr-4">
          <Map
            center={mapCenter}
            scrollWheelZoom={false}
            zoom={15}
            zoomControl={false}
            dragging={false}
            height={"271px"}
            width={"392px"}
            showMarker={false}
            infoOn={false}
            editOn={false}
          />
        </div>
        <div className="grow lg:relative">
          <div className="flex justify-between items-center mb-2">
            <h1 className="text-xl font-bold mt-4 lg:mt-0">{name}</h1>
            <div className="flex">
              <Link
                to="/field-details"
                state={{ field: field }}
                className="bg-[#111827] text-white font-medium text-sm leading-none flex items-center justify-center h-10 p-3 rounded-md hover:bg-gray-700"
              >
                {t("fieldListPage.fieldDetailsButton")}{" "}
                <ArrowRightIcon className="block h-4 w-4 ml-2.5" />
              </Link>
              <Popup
                trigger={
                  <button className="bg-white border border-[#E4E4E4] hover:bg-[#E4E4E4] active:bg-[#E4E4E4]  text-[#111827] rounded-md ml-3">
                    <EllipsisVerticalIcon className="block h-4 w-4 mx-3" />
                  </button>
                }
                position="bottom right"
                nested
              >
                <div className="shadow rounded-md border border-[#E4E4E4] px-2 py-1 bg-white">
                  <ul className="text-xs">
                    <li>
                      {" "}
                      <AlertModalField
                        deleteFieldOnClick={deleteFieldOnClick}
                      />
                    </li>
                  </ul>
                </div>
              </Popup>
            </div>
          </div>
          <p className="text-sm text-slate-500 mb-6">{lastMeasurementText}</p>
          <div className="columns-4 mb-8">
            {getLastValidMeasurementData().map(
              ({ title, measurements, datetime, unit }) => {
                const { min, max } = calculateMinMax(measurements);
                return (
                  <Measurement
                    key={title}
                    title={t(title)}
                    minValue={min}
                    maxValue={max}
                    datetime={datetime}
                    unit={determineUnit(min, unit)}
                  />
                );
              }
            )}
          </div>
          <h2 className="text-black text-base font-bold mt-4">
            {t("fieldListPage.sondasOnFieldSectionTitle")}
          </h2>
          <div className="mb-4">
            {renderSondaList(sondas)}
            <div className="inline-block">
              <Link
                to="/new-sonda"
                state={{ field: field }}
                className="flex items-center mt-3 rounded-md bg-white px-4 text-sm text-black font-medium shadow-sm hover:bg-[#E4E4E4] focus:outline-none focus:ring-2 focus:ring-gray-100 focus:ring-offset-2 border border-gray-200"
              >
                {t("fieldListPage.addSondaButton")}
                <span className="ml-2 text-2xl font-light">+</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </ActionPanel>
  );
};

export default FieldListItem;
