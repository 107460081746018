import { deleteSondaSuccess } from "../store/sonda/sondaSlice";

const de = {
  translation: {
    common: {
      sonda: "Sonda",
      moisture: "Bodenfeuchte",
      temperature: "Temperatur",
      nitrate: "Nitrat",
      phosphate: "Phosphat",
      ph: "pH",
      ec: "EC",
      errorMessage: "Etwas ist schiefgelaufen",
      footer:
        "Wir sind für Sie da. Kontaktieren Sie uns unter info@nerite.tech",
    },
    navBar: {
      myFields: "Meine Felder",
      myAccount: "Mein Account",
      about: "Über",
      termsOfService: "Nutzungsbedingungen",
      logOut: "Ausloggen",
      openMainMenu: "Hauptmenü öffnen",
    },
    logInPage: {
      logIn: "Anmelden",
      email: "E-Mail*",
      password: "Passwort*",
    },
    startMonitoringPage: {
      startMonitoringTitle: "Bodenüberwachung starten",
      startMonitoringText:
        "Fügen Sie Ihr erstes Feld hinzu und verbinden Sie eine Sonda um die Bodenüberwachung zu starten.",
      startMonitoringButton: "Feld hinzufügen",
    },
    addFieldPage: {
      addFieldTitle: "Feld hinzufügen",
      fieldName: "Feldname*",
      cancelButton: "Abbrechen",
      saveButton: "Feld speichern",
    },
    newSondaPage: {
      newSondaTitle: "Sonda-Code eingeben",
      newSondaSubTitle:
        "Fügen Sie Sondas zu ihrem Feld hinzu, um die Bodenüberwachung zu starten.",
      newSondaText:
        "Bitte installieren Sie die Sonda vor der Anmeldung im Feld. Sie finden den Aktivierungscode in der Sonda-Box und in der Bestätigungs-E-Mail.",
      newSondaLaterButton: "Später erledigen",
      newSondaConnectButton: "Verbinden",
      saveFieldSuccessAlert:
        "Ihr Feld wurde hinzugefügt. Fügen Sie eine Sonda hinzu, um mit der Bodenüberwachung zu beginnen.",
    },
    addSondaPage: {
      addSondaTitle: "Bitte bestätigen Sie den Standort Ihrer Sonda.",
      cancelButton: "Abbrechen",
      assignSondaButton: "Sonda einem Feld zuweisen",
      sondaError:
        "Wir konnten Ihre Sonda nicht lokalisieren. Bitte versuchen Sie es erneut oder kontaktieren Sie info@nerite.tech",
    },
    fieldListPage: {
      allFields: "Alle felder",
      addFieldButton: "Feld hinzufügen",
      noMeasurements: "Es wurden noch keine Messungen vorgenommen.",
      recentMeasurements: "Aktuelle Messungen wurden durchgeführt am",
      battery: "Batterie",
      connection: "Signalstärke",
      sondasOnFieldSectionTitle: "Sondas auf dem Feld",
      noSonda: "Bisher wurden noch keine Sondas hinzugefügt.",
      addSondaButton: "Sonda hinzufügen",
      fieldDetailsButton: "Siehe felddetails",
      saveSondaSuccessAlert: "Neue Sonda hinzugefügt",
      deleteSondaSuccessAlert: "Sonda wurde gelöscht.",
      deleteFieldSuccessAlert: "Feld wurde entfernt",
      deleteFieldModalContent: "Feld löschen?",
      deleteSonda: "Sonda löschen",
      deleteField: "Feld löschen",
      closeModal: "Schließen",
      deleteSondaModalContent: "Sonda löschen?",
    },
    fieldDetailsPage: {
      backLink: "Zurück zur Feldübersicht",
      selectMeasurementType: "Messart auswählen",
      optimalRange: "Optimaler Bereich",
      saveOptimalRange: "Speichern",
    },
    imgAlt: {
      close: "Close icon",
      tickBox: "Tick box icon",
    },
    mapDetails: {
      markArea: "Markieren Sie ein Gebiet für Satellitendaten.",
      usePanel:
        "Benutzen Sie das Bedienfeld in der oberen rechten Ecke der Karte.",
      loading: "Laden...",
      downloadingData: "Daten werden aktualisiert...",
      imageDate: "Aufnahme vom:",
      zoomInText: "Vergrößern",
      zoomOutText: "Herauszoomen",
    },
    optimalRangeErrors: {
      moistureNotInRange:
        "Der optimale Bereich ist inkorrekt. Der geeignete Bereich für Bodenfeuchtigkeit liegt zwischen 0 und 100%.",
      temperatureNotInRange:
        "Der optimale Bereich ist inkorrekt. Die minimale Temperatur kann nicht kleiner als -273,15 °C sein.",
      nitrateNotInRange:
        "Der optimale Bereich ist inkorrekt. Der Minimalwert für Nitrat muss größer als oder gleich 0 sein.",
      phosphateNotInRange:
        "Der optimale Bereich ist inkorrekt. Der Mindestwert von Phosphat muss größer oder gleich 0 sein.",
      phNotInRange:
        "Der optimale Bereich ist inkorrekt. Der richtige pH-Bereich liegt zwischen 0 und 14.",
      ecNotInRange:
        "Der ideale Wertebereich ist nicht korrekt. Der minimale Wert der elektrischen Leitfähigkeit muss größer sein als 0.",
      minHigherThanMax:
        "Der Minimalwert kann nicht höher sein als der Maximalwert.",
    },

    scriptNames: {
      moistureIndex: "Bodenfeuchte Index",
      ndvi: "NDVI",
      trueColor: "Satellitenbild",
    },
    sondaErrors: {
      lowBattery: "Batteriestand niedrig",
      soilSamplingError: "Fehler bei der Entnahme der Bodenlösung",
      timeSynchronizationError: "Fehler bei der Zeitsynchronisation",
      locationSynchronizationError: "Fehler bei der Standortsynchronisierung",
      nutrientSensorError: "Nährstoffsensor-Fehler",
      pneumaticSystemError: "Fehler im Pneumatiksystem",
      soilTemperatureSensorError: "Fehler im Bodentemperatursensor",
      solutionTemperatureSensorError: "Fehler im Flüssigkeitstemperatursensor",
      caseClimateSensorError: "Gehäuse-Klimasensor-Fehler",
      caseTemperatureTooHigh: "Temperatur im Gehäuses zu hoch",
      caseTemperatureTooLow: "Temperatur im Gehäuses zu niedrig",
      softwareError: "Softwarefehler",
      soilTooDryForSampling: "Boden für Probenahme zu trocken",
      solutionExtractionError: "Fehler beim Extrahieren der Bodenlösung",
    },
  },
};

export default de;
