import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";

import InputField from "../components/InputField";
import { useAppDispatch } from "../store";
import {
  clearSaveFieldSuccess,
  selectSaveFieldSuccess,
} from "../store/fields/fieldsSlice";
import {
  checkSonda,
  clearCheckSondaSuccess,
  selectCheckSondaSuccess,
} from "../store/sonda/sondaSlice";

const NewSonda: FC = () => {
  const dispatch = useAppDispatch();
  const { state } = useLocation();
  const navigate = useNavigate();
  const checkSondaSuccess: boolean = useSelector(selectCheckSondaSuccess);
  const [sondaCode, setSondaCode] = useState("");
  const { t } = useTranslation("translation");

  const handleChange = (event) => {
    setSondaCode(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(checkSonda(sondaCode));
  };

  useEffect(() => {
    dispatch(clearSaveFieldSuccess());
    if (state == undefined) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    if (checkSondaSuccess) {
      dispatch(clearCheckSondaSuccess());
      navigate("/add-sonda", { state: { field: state.field } });
    }
  }, [checkSondaSuccess]);

  return (
    <div className="sm:mx-auto sm:w-full mt-24 sm:max-w-xl">
      <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <form onSubmit={handleSubmit}>
          <div className="grid place-items-center">
            <h1 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
              {t("newSondaPage.newSondaTitle")}
            </h1>
            <div className="my-4 max-w-md text-base text-gray-900 text-center">
              <p>{t("newSondaPage.newSondaSubTitle")}</p>
            </div>
            <div className="flex justify-between my-4 max-w-md text-sm text-gray-500 text-center">
              <InputField
                id="sondaCode"
                name="sondaCode"
                onChangeHandler={(e) => handleChange(e)}
              />
            </div>
            <div className="mb-16 max-w-sm text-sm text-gray-400 text-center">
              <p>{t("newSondaPage.newSondaText")}</p>
            </div>
          </div>
          <div className="flex justify-between">
            <Link
              to="/"
              className="rounded-md border border-transparent bg-gray-200 py-2 px-8 text-sm font-medium shadow-sm hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-200 focus:ring-offset-2"
            >
              {t("newSondaPage.newSondaLaterButton")}
            </Link>
            <button
              className="rounded-md border border-transparent bg-amber-300 py-2 px-8 text-sm font-medium shadow-sm hover:bg-amber-400 focus:outline-none focus:ring-2 focus:ring-amber-300 focus:ring-offset-2"
              type="submit"
            >
              {t("newSondaPage.newSondaConnectButton")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NewSonda;
